import React from 'react';

const MineralWater = ({size}) => (
  <svg width={size} height={size} x="0px" y="0px" viewBox="0 0 468.293 468.293" style={{enableBackground: 'new 0 0 468.293 468.293'}}>
    <path style={{fill: '#EBF0F3'}} d="M306.471,304.064H161.822c-10.345,0-18.732-8.386-18.732-18.732v-17.299c0-10.345,8.386-18.732,18.732-18.732h144.649c10.345,0,18.732,8.386,18.732,18.732v17.299C325.203,295.677,316.816,304.064,306.471,304.064z"/>
    <g>
      <path style={{fill: '#E1E6E9'}} d="M325.176,101.971v128.338c0,10.504-8.48,18.985-18.985,18.985H162.096c-10.505,0-18.985-8.48-18.985-18.985V101.971c0-24.427,19.808-44.298,44.298-44.298h93.469C305.368,57.673,325.176,77.544,325.176,101.971z"/>
      <path style={{fill: '#E1E6E9'}} d="M306.471,358.807H161.822c-10.345,0-18.732-8.386-18.732-18.732v-17.299c0-10.345,8.386-18.732,18.732-18.732h144.649c10.345,0,18.732,8.386,18.732,18.732v17.299C325.203,350.42,316.816,358.807,306.471,358.807z"/>
    </g>
    <path style={{fill: '#EBF0F3'}} d="M306.471,413.55H161.822c-10.345,0-18.732-8.386-18.732-18.732v-17.299c0-10.345,8.386-18.732,18.732-18.732h144.649c10.345,0,18.732,8.386,18.732,18.732v17.299C325.203,405.163,316.816,413.55,306.471,413.55z"/>
    <g>
      <path style={{fill: '#E1E6E9'}} d="M306.471,468.293H161.822c-10.345,0-18.732-8.386-18.732-18.732v-17.299c0-10.345,8.386-18.732,18.732-18.732h144.649c10.345,0,18.732,8.386,18.732,18.732v17.299C325.203,459.906,316.816,468.293,306.471,468.293z"/>
      <rect x="199.28" y="43.414" style={{fill: '#E1E6E9'}} width="69.738" height="14.274"/>
    </g>
    <path style={{fill: '#44C4A1'}} d="M274.096,43.415h-79.899V12.488C194.197,5.591,199.788,0,206.685,0h54.923c6.897,0,12.488,5.591,12.488,12.488V43.415z"/>
    <rect x="143.11" y="107.289" style={{fill: '#27A2DB'}} width="182.072" height="98.085"/>
  </svg>
);

export default MineralWater;
