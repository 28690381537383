import React from 'react';
import MineralWater from './MineralWater';
import WiFi from './WiFi';
import SimCard from './SimCard';
import BaliIcon from './Bali';
import WhatsApp from './WhatsApp';
import Telegram from './Telegram';
import Jimmy from './Jimmy.jpg';

const request = require('superagent');

class MyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: '',
      arrivalFlightNumber: '',
      name: '',
      arrivalDate: '',
      arrivalTime: '',
      email: '',
      BaliDestination: '',
      shownFormPart: 1,
      oneCar: true,
      twoCars: false,
      moreCars: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'radio' ? target.checked : target.value;
    const name = target.name;
    if (name === 'oneCar') {
      this.setState({twoCars: false, moreCars: false});
    } else if (name === 'moreCars') {
      this.setState({oneCar: false, twoCars: false});
    } else if (name === 'twoCars') {
      this.setState({oneCar: false, moreCars: false});
    }

    this.setState({
      [name]: value
    });
  }
  showNextFormPart(index) {
    const {arrivalFlightNumber, arrivalDate, arrivalTime, BaliDestination, name, email, oneCar, twoCars, moreCars} = this.state;
    if (index === 2 && (arrivalFlightNumber !== '' && arrivalDate !== '' && arrivalTime !== '' && BaliDestination !== '')) {
      console.log(RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}/).test(arrivalDate));
      if (!RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}/).test(arrivalDate)) {
        this.setState({validation: 'Please enter the date in the format of YYYY-MM-DD'});
      } else {
        this.setState({shownFormPart: 2, validation: ''});
      }
    } else if (index === 3 && (name !== '' && email !== '')) {
      if (!RegExp(/\S+@\S+\.\S+/).test(email)) {
        this.setState({validation: 'Please enter valid email'});
      } else {
        this.setState({shownFormPart: 3, validation: ''});
        const carsAmount = oneCar === true ? '1' : twoCars === true ? '2' : moreCars === true ? '3+' : '1';
        const data = {
          arrivalFlightNumber,
          arrivalDate,
          arrivalTime,
          BaliDestination,
          name,
          email,
          carsAmount
        };
        console.log(JSON.stringify(data));
        request
        .post('https://hooks.zapier.com/hooks/catch/2282317/zn9jqe/')
        .type('form')
        .send(data)
        .end(error => {
          if (error) {
            this.setState({validation: 'Could not send information, please try again.'});
          }
        });
      }
    } else {
      this.setState({validation: 'Please fill out the empty fields. We need this information to arrange your transfer properly.'});
    }
  }
  render() {
    const {
      arrivalFlightNumber,
      name,
      arrivalDate,
      email,
      shownFormPart,
      BaliDestination,
      oneCar,
      twoCars,
      moreCars,
      arrivalTime,
      validation
    } = this.state;
    return (
      <div className="pa3">
        <h1 className="tc avenir fw1">
          <span className="pb3">Simple Bali Transfer</span>
          <br/>
          <i className="mt3 em em-palm_tree"/>
          <i className="mt3 em em-sunny pl2"/>
          <i className="mt3 em em-surfer pl2"/>
        </h1>
        <div className="pv3 flex flex-column justify-between center mw7">
          <div className="flex flex-row flex-wrap w-100 justify-center items-between">
            <p className={`${validation === '' ? 'flex o-0' : 'flex'} flex-row items-top justify-center h1 red`}>{validation}</p>
            <form onSubmit={event => event.preventDefault()}>
              <div className={`${shownFormPart === 1 ? 'flex' : 'dn'} fadeIn flex-row h-320px-s h-170px-ns flex-wrap items-end-l items-start justify-center`}>
                <div className="flex flex-row items-end w-100 justify-center flex-wrap">
                  <div className="pb3 flex flex-column mw14rem ph2-ns w-100">
                    <label className="pb2 flex flex-row items-center" htmlFor="BaliDestination">
                      <BaliIcon size="30px"/>
                      <span className="pl2 fw4">Destination in Bali</span>
                    </label>
                    <input onChange={this.handleInputChange} placeholder="ex. Nusa Dua" value={BaliDestination} name="BaliDestination" required/>
                  </div>
                  <div className="pb3 flex flex-column mw14rem ph2-ns w-100">
                    <label className="pb2 flex flex-row items-center" htmlFor="flightNumber">
                      <i className="em em-airplane pr2 f7"/>
                      <span className="fw4">Arrival flight number</span>
                    </label>
                    <input onChange={this.handleInputChange} placeholder="ex. GA-881" value={arrivalFlightNumber} name="arrivalFlightNumber" required/>
                  </div>
                </div>
                <div className="flex flex-row items-end w-100 justify-center flex-wrap">
                  <div className="pb3 flex flex-column mw14rem ph2-ns w-100">
                    <label className="pb2 flex flex-row items-center" htmlFor="arrivalDate">
                      <i className="em em-date pr2 f7"/>
                      <span className="fw4">Date of arrival</span>
                    </label>
                    <input className={arrivalDate === '' ? 'placeholder' : ''} onChange={this.handleInputChange} value={arrivalDate} name="arrivalDate" type="date" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" title="Please enter a date in a format YYYY-MM-DD" required/>
                  </div>
                  <div className="pb3 flex flex-column mw14rem ph2-ns w-100">
                    <label className="pb2 flex flex-row items-center" htmlFor="arrivalTime">
                      <i className="em em-alarm_clock pr2 f7"/>
                      <span className="fw4">Time of arrival</span>
                    </label>
                    <input className={arrivalTime === '' ? 'placeholder' : ''} onChange={this.handleInputChange} value={arrivalTime} placeholder="ex. 10:30 AM" name="arrivalTime" title="Please enter time in a format HH/MM AM or PM" required/>
                  </div>
                </div>
              </div>
              <div className={`${shownFormPart === 1 ? 'flex' : 'dn'} pt3 flex-row w-100 justify-center items-end`}>
                <button type="submit" className="bookButton w-100 mw5 pointer dim" onClick={this.showNextFormPart.bind(this, 2)}>Book a transfer</button>
              </div>
            </form>
            <form onSubmit={event => event.preventDefault()}>
              <div className={`${shownFormPart === 2 ? 'flex' : 'dn'} fadeIn flex-row h-320px-s h-170px-ns flex-wrap items-end-l items-start justify-center`}>
                <div className="flex flex-row w-100 items-end justify-center flex-wrap">
                  <div className="pb3 flex flex-column mw5 ph2-ns w-100">
                    <label className="pb2 flex flex-row items-center" htmlFor="name">
                      <i className="em em-smile pr2 f7"/>
                      <span className="fw4">Enter your name</span>
                    </label>
                    <input onChange={this.handleInputChange} placeholder="Ex. John Walker" value={name} name="name" required/>
                  </div>
                  <div className="pb3 flex flex-column mw5 ph2-ns w-100">
                    <label className="pb2 flex flex-row items-center" htmlFor="email">
                      <i className="em em-email pr2 f7"/>
                      <span className="fw4">Your contact Email</span>
                    </label>
                    <input onChange={this.handleInputChange} placeholder="Ex. johnwalker@gmail.com" value={email} type="email" name="email" required/>
                  </div>
                </div>
                <div className="flex flex-row w-100 items-center justify-center flex-wrap">
                  <div className="pb3 flex flex-column ph2-ns w-100 items-center">
                    <label className="pb3 flex flex-row items-center justify-center flex-wrap">
                      <span className="fw4">How many cars</span>
                      <span className="fw4 pl3-ns">(1 car per 4 people with luggage)</span>
                    </label>
                    <div className="carsRadioInput flex flex-row w-100 justify-between items-center mw5">
                      <label className={oneCar ? 'selectedCarAmount white fw6' : 'white fw6'}><input type="radio" name="oneCar" onChange={this.handleInputChange} checked={oneCar} value={1}/>1</label>
                      <label className={twoCars ? 'selectedCarAmount white fw6' : 'white fw6'}><input type="radio" name="twoCars" onChange={this.handleInputChange} checked={twoCars} value={2}/>2</label>
                      <label className={moreCars ? 'selectedCarAmount white fw6' : 'white fw6'}><input type="radio" name="moreCars" onChange={this.handleInputChange} checked={moreCars} value={3}/>3+</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${shownFormPart === 2 ? 'flex' : 'dn'} pt3 flex-row w-100 justify-center items-end`}>
                <button type="submit" className="bookButton w-100 mw5 pointer dim" onClick={this.showNextFormPart.bind(this, 3)}>Finish the booking</button>
              </div>
            </form>
            <div className={`${shownFormPart === 3 ? 'flex' : 'dn'} fadeIn flex-column flex-nowrap-ns flex-wrap items-end justify-center`}>
              <p className="f3 w-100 tc">
                Alright{name ? `, ${name}` : ''}!
              </p>
              <p className="f5 w-100 tc mb1">
                We will meet you at the airport when flight <span className="fw6">{arrivalFlightNumber}</span> arrives at <span className="fw6">{arrivalTime}</span> on <span className="fw6 nowrap">{new Date(arrivalDate).toDateString().slice(4)}</span>. We will have greeting sign with your name on it and transfer you to <span className="fw6 nowrap">{BaliDestination}</span>.
              </p>
              <p className="f5 w-100 tc mt0">
                {email && `Your contact email is ${email}`}
              </p>
              <p className="f4 w-100 tc mv0">
                If you have any questions, you can contact us
              </p>
              <div className="f4 w-100 tc flex min-h-40px flex-row flex-wrap items-center justify-center">
                <p className="mv2">via </p>
                <div className="ph2 flex flex-row items-center">
                  <WhatsApp size="25px"/>
                  <p className="pl2 mv2">WhatsApp</p>
                </div>
                <p className="mv2 fw6 green">+62 8191 663 9069</p>
              </div>
              <div className="f4 w-100 tc flex min-h-40px flex-row flex-wrap items-center justify-center">
                <p className="mv2">or</p>
                <div className="ph2 flex flex-row items-center">
                  <Telegram size="25px"/>
                  <p className="mv2 pl2">Telegram</p>
                </div>
                <p className="fw6 green mv2">+62 8191 663 9069</p>
              </div>
              <p className="f3 w-100 tc mb0">
                Have a nice flight!
              </p>
            </div>
          </div>
          <div className="flex flex-row flex-wrap items-center pt5 w-50-l w-100">
            <div className="flex flex-column items-start">
              <label className="pb3 flex flex-row w-100 justify-center f4 fw6">Benefits</label>
              <div className="flex flex-row flex-wrap w-100 items-start tc justify-center">
                <div className="flex flex-column ph3 pt4 w10rem items-center justify-center">
                  <SimCard size="3rem"/>
                  <div className="pt2">
                    <span className="fw4">Free Sim-Card</span>
                    <br/>
                    <span className="fw4">with internet!</span>
                  </div>
                </div>
                <div className="flex flex-column ph3 pt4 w10rem items-center justify-center">
                  <MineralWater size="3rem"/>
                  <span className="pt2 fw4">Free Water</span>
                </div>
                <div className="flex flex-column ph3 pt4 w10rem items-center justify-center">
                  <WiFi size="3rem"/>
                  <div className="pt2">
                    <span className="fw4">Free Wi-fi</span>
                    <br/>
                    <span className="fw4">in the car</span>
                  </div>
                </div>
                <div className="flex flex-column ph3 pt4 w10rem items-center justify-center">
                  <i className="f2 em em-face_with_monocle"/>
                  <span className="pt2 fw4">Fluent English</span>
                </div>
                <div className="flex flex-column ph3 pt4 w10rem items-center justify-center">
                  <i className="f2 em em-car"/>
                  <span className="pt2 fw4">Comfortable cars</span>
                </div>
                <div className="flex flex-column ph3 pt4 w10rem items-center justify-center">
                  <i className="f2 em em-beach_with_umbrella"/>
                  <div className="pt2">
                    <span className="fw4">Transfer</span>
                    <br/>
                    <span className="fw4">to any place</span>
                    <br/>
                    <span className="fw4">in Bali</span>
                  </div>
                </div>
                <div className="flex flex-column ph3 pt4 w10rem items-center justify-center">
                  <i className="f2 em em-moneybag"/>
                  <div className="pt2">
                    <span className="fw4">We accept</span>
                    <br/>
                    <span className="fw4">any currency</span>
                  </div>
                </div>
              </div>
              <p className="f4 w-100 tc pt4 mb0 fw4">
                Only for <span className="green fw6">$20</span> one-way / <span className="green fw6">$40</span> here and back*
              </p>
              <p className="f6 w-100 tc fw4">
                * These prices are valid for Kuta and Nusa Dua area, for prices to other destinations please contact us.
              </p>
            </div>
            <div className="flex flex-column items-center pt4 w-100">
              <label className="pb3 flex flex-row w-100 justify-center f4 fw6">Frequently Asked Questions</label>
            </div>
            <div className="flex flex-column lh-30px mw7-ns mw6 center">
              <div className="flex flex-column pv3">
                <p className="gray fw6">How many sim cards do you give?</p>
                <p className="fw4 tj">We offer you 1 ready to use Sim card included into the transfer package, but we can easily provide more sim cards per request. Just contact us.</p>
              </div>
              <div className="flex flex-column pv3">
                <p className="gray fw6">How many people can you transfer?</p>
                <p className="fw4 tj">One transfer package fits 4 people with their luggage. If you need more place, mention how many cars you need in the form, or contact us, we are happy to transfer all of your friends safely.</p>
              </div>
              <div className="flex flex-column pv3">
                <p className="gray fw6">What if my flight is cancelled or delayed?</p>
                <p className="fw4 tj">Brace yourself! And text us, we will still meet you when you finally arrive.</p>
              </div>
              <div className="flex flex-column pv3">
                <p className="gray fw6">Who are you anyway?</p>
                <div className="flex flex-row-ns flex-column w-100">
                  <div className="flex flex-column items-center justify-center pv0-ns pv3 w-300px-ns flex-shrink-0">
                    <img className="w-300px-ns w-150px-s h-auto br-pill" src={Jimmy}/>
                  </div>
                  <div className="flex flex-column items-center justify-center">
                    <p className="fw4 tj ml4-l ml3-m">I am Jimmy, founder of Avindara Bali Tours and Travel. Welcome to Bali! We are here to make your vacation perfect. Contact us anytime via WhatsApp (+62 8191 663 9069) or Telegram (+62 8135 300 2569).</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-column self-end justify-center items-center tc pt5">
          <p className="fw4 f5 gray tc">
           Service provided by Avindara Bali Tours
          </p>
        </div>
        <style>
          {`
            .flex-shrink-0 {
              flex-shrink: 0;
            }
            @media screen and (max-width: 30em) {
              .h-320px-s {
                height:320px;
              }
              .w-150px-s {
                width: 150px;
              }
            }
            @media screen and (min-width: 30em) {
              .h-170px-ns {
                height:170px;
              }
              .w-300px-ns {
                width: 300px;
              }
            }
            .min-h-40px {
              min-height: 40px;
            }
            .h-160px {
              height: 160px;
            }
            .lh-30px {
              line-height: 30px;
            }
            @-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
            @-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
            @keyframes fadeIn { from { opacity:0; } to { opacity:1; } }


            .fadeIn {
              opacity:0;  /* make things invisible upon start */
              -webkit-animation:fadeIn ease-in 0.3s;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
              -moz-animation:fadeIn ease-in 0.3s;
              animation:fadeIn ease-in 0.3s;

              -webkit-animation-fill-mode:forwards;  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
              -moz-animation-fill-mode:forwards;
              animation-fill-mode:forwards;

              -webkit-animation-duration:0.3s;
              -moz-animation-duration:0.3s;
              animation-duration:0.3s;
            }
            .green {
              color: #42B273;
            }
            h1, label, li, span, p {
              font-family:'Open Sans',sans-serif;
            }
            h1 {
              font-weight: 100;
            }
            .mw13rem {
              max-width: 13rem;
            }
            .mw14rem {
              max-width: 14rem;
            }
            .w10rem {
              width: 10rem;
            }
            .bookButton {
              border: 1px solid #42B273;
              background-color: #42B273;
              color: white;
              font-size: 1.5rem;
              border-radius:0.2rem;
              padding: 0.3rem 0.5rem;
            }
            input:invalid {
              border-color: red;
            }
            input:not([type="radio"]), .blurred, select {
              -webkit-appearance:     none;
              -moz-appearance:        none;
              -ms-appearance:         none;
              -o-appearance:          none;
              appearance:             none;
              box-sizing: border-box;
              background-color: white;
              box-shadow: none !important;
              border-style:solid;
              font-family:'Open Sans',sans-serif;
              border-width:1px;
              border-color: #cecccc;
              padding: 0.3rem 0.5rem;
              font-size: 1rem;
              line-height: 1rem;
              border-radius:0.2rem;
              height: 35px;
              width: 100%;
            }
            input:not([type="radio"]):focus:not(.error), input:not([type="radio"]):hover:not(error), .focused, select:focus:not(.error), select:hover:not(error) {
              outline: none;
              border-color: #42B273;
            }
            input:not([type="radio"]).error {
              border-color: #FF00BF !important;
            }
            .carsRadioInput input[type="radio"] {
              display: none;
              visibility: hidden;
              height: 0;
              width: 0;
              opacity: 0;
            }

            .carsRadioInput label {
              display: inline-block;
              background-color: #999;
              padding: 6px 11px;
              font-family: Arial;
              font-size: 16px;
              cursor: pointer;
              width: 40px;
              text-align: center;
            }

            .carsRadioInput input[type="radio"]:checked+label,.carsRadioInput label.selectedCarAmount {
              background-color: gold;
            }
            .w-100 {
              width:100%;
            }
            :-webkit-autofill { color: #fff !important; }
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: #666666;
            }
            ::-moz-placeholder { /* Firefox 19+ */
              color: #666666;
            }
            :-ms-input-placeholder { /* IE 10+ */
              color: #666666;
            }
            :-moz-placeholder { /* Firefox 18- */
              color: #666666;
            }
            .placeholder {
              color: #666666;
            }
            .gray {
              color: #999999;
            }
          `}
        </style>
      </div>
    );
  }
}

export default MyPage;
