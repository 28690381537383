import React from 'react';

const WiFi = ({size}) => (
  <svg x="0px" y="0px" height={size} width={size} viewBox="0 0 286.082 286.082" style={{enableBackground: 'new 0 0 286.082 286.082'}}>
    <g>
      <path style={{fill: '#2394BC'}} d="M143.097,219.074c-9.905,0-17.95,8.01-17.95,17.86c0,9.869,8.036,17.86,17.95,17.86s17.941-8.001,17.941-17.86S153.011,219.074,143.097,219.074z M281.199,84.906c-36.91-34.577-85.977-53.617-138.155-53.617S41.798,50.329,4.888,84.924c-6.517,6.105-6.517,15.992,0,22.107c3.254,3.048,7.527,4.577,11.791,4.577s8.537-1.529,11.8-4.577C59.078,78.345,99.769,64.23,143.035,64.23c43.284,0,83.966,14.115,114.565,42.783c6.517,6.105,17.074,6.105,23.599,0C287.706,100.907,287.715,91.011,281.199,84.906z M142.901,93.809c-38.564,0-74.821,14.911-102.094,41.978c-6.803,6.758-6.803,17.709,0,24.467c3.406,3.388,7.866,5.077,12.327,5.077s8.921-1.69,12.318-5.077c20.685-20.533,48.2-32.968,77.449-32.968c29.24,0,56.764,12.443,77.44,32.968c6.812,6.758,17.843,6.758,24.645,0c6.812-6.758,6.812-17.709,0-24.467C217.713,108.72,181.446,93.809,142.901,93.809z M143.053,156.357c-21.812,0-42.309,8.751-57.738,24.636c-6.392,6.579-6.392,17.271,0,23.85c3.182,3.281,7.375,4.925,11.567,4.925c4.184,0,8.367-1.645,11.558-4.925c9.243-9.529,21.535-15.331,34.613-15.331c13.06,0,25.352,5.793,34.613,15.313c6.392,6.579,16.734,6.579,23.135,0c6.374-6.579,6.374-17.253,0-23.832C185.371,165.108,164.864,156.357,143.053,156.357z"/>
    </g>
  </svg>
);

export default WiFi;
