import React from 'react';

const BaliIcon = ({size}) => (
  <svg x="0px" y="0px" height={size} width={size} viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512'}} xmlSpace="preserve">
    <path style={{fill: '#42B273'}} fill="#42B273" d="M291.879,425.369l-20.991-4.998l-16.822-21.34l10.645-13.104l10.115-5.248l5.547-17.282l-3.498-12.684c-10.085-9.616-64.361-61.292-69.859-66.54l-29.627-20.521l-62.042-26.178L63.37,247.869l-18.76-10.995c-3.528-5.418-7.996-11.345-8.386-15.403C31.866,214.124,5.118,177.6,0,170.563l4.298-35.544l50.517-26.688l14.863-1.239l20.081,14.603c0.5,0.14,74.077,20.341,74.966,20.591l49.748-6.417l22.72-14.394l9.806-15.153l37.773-15.923l0.83-3.768l45.889,9.996l18.062,10.675h10.995l27.827,15.653l8.176,1l1.859-2.669l53.796,37.853l27.068,34.684l17.532,9.176L512,218.192v17.192l-8.806,17.612l-24.989,13.784l-9.056,16.303c-50.038,14.414,73.807-21.29-96.856,27.917l-38.982,28.247l4.338,17.642l-2.839,10.415l-1.599,1.529l-11.395,10.945l2.099,17.872l-11.275,21.99L291.879,425.369z M280.004,406.128l11.555,2.729l10.055-2.789l5.847-11.415l-2.459-20.931l15.543-14.923l0.58-2.149l-5.967-24.049c53.866-38.982,49.358-35.984,51.097-36.514l0,0l92.129-26.558l8.076-14.543l24.659-13.604l4.898-9.786v-6.787l-8.786-8.786l-18.422-9.646l-27.708-35.554l-43.6-30.686l-14.134-1.659l-26.988-15.173h-11.235l-19.57-11.605l-33.085-7.217l-34.784,14.653l-8.996,13.994l-28.667,18.152c-60.713,7.836-55.735,7.427-57.774,6.917l0,0c-1.549-0.47,3.618,1-79.524-21.84l-17.642-12.834l-5.687,0.47l-40.232,21.26l-2.549,20.991c30.346,41.621,35.524,48.878,35.764,53.146c0.53,0.93,1.559,2.599,3.518,5.568l10.355,6.057l46.979-9.366c2.307-0.817,4.853-0.61,6.997,0.57l64.891,27.418l31.986,22.18l73.967,70.448l5.927,21.56c-3.908,12.175-9.386,29.267-8.996,28.097l-12.994,6.747l-0.49,0.59L280.004,406.128z"/>
    <path style={{fill: '#42B273'}} fill="#42B273" d="M231.086,254.636l-19.991-13.864l-91.709-42.831l-5.747,1.149h-22.15l-0.93,4.608l-18.892,3.778c-1.889-4.121-4.176-8.047-6.827-11.725c-4.398-6.477-12.524-17.872-25.339-35.494l0.12-1l21.65-11.355l11.825,8.606l5.338,1.439l77.965,21.43l11.585,0.77l59.673-7.697l36.853-23.34l7.996-12.324l22.12-9.316l22.83,4.998l21.69,12.824h11.495l25.788,14.503l12.994,1.529l36.384,25.598l28.627,36.724l11.995,6.277l-16.063,8.856l-6.737,12.125l-88.18,25.419l-48.778,35.284l-8.996,6.487L231.086,254.636z"/>
    <path style={{fill: '#42B273'}} fill="#42B273" d="M462.322,406.148l-24.989-5.547c-21.85-18.152-2.649,0.14-44.98-40.672l28.907-31.426l12.095,7.057l14.274-6.247l18.332,15.713V355.3l14.234,13.394v15.513L462.322,406.148z M443.601,385.577l12.564,2.789l8.066-9.876v-2.909l-14.234-13.374v-9.826l-5.128-4.388l-12.524,5.478l-7.996-4.668l-9.636,10.465C423.2,367.525,437.264,381.119,443.601,385.577z"/>
  </svg>
);

export default BaliIcon;
