import React from 'react';

const SimCard = ({size}) => (
  <svg x="0px" y="0px" height={size} width={size} viewBox="0 0 504.123 504.123" style={{enableBackground: 'new 0 0 504.123 504.123'}}>
    <path style={{fill: '#EFEFEF'}} d="M330.043,0c-10.634,0-20.874,4.332-28.357,11.815L27.569,284.751c-7.483,7.483-11.815,17.723-11.815,28.751s4.332,20.874,11.815,28.751l150.843,150.055c7.483,7.483,17.723,11.815,28.357,11.815s20.874-4.332,28.357-11.815l241.428-240.246c7.483-7.483,11.815-18.117,11.815-28.751V163.84c0-10.634-3.938-20.48-11.028-27.963L359.188,12.209C351.705,4.726,341.071,0,330.043,0z"/>
    <path style={{fill: '#EFC75E'}} d="M356.037,274.117l-131.151,133.12c-8.271,8.271-21.268,8.271-29.538,0l-78.769-80.345c-8.271-8.271-8.271-21.662,0-29.932L247.73,163.84c8.271-8.271,21.268-8.271,29.538,0l78.769,80.345C364.308,252.455,364.308,265.846,356.037,274.117z"/>
    <path style={{fill: '#D3D6D8'}} d="M341.071,92.948c2.363-2.757,6.302-1.575,9.058,1.182c3.545,3.545,3.938,7.483,6.302,9.846c2.363,2.363,6.302,1.969,8.665-0.394c4.726-4.726-2.363-14.572-5.908-18.117c-8.271-8.271-20.48-9.846-28.751-1.182c-14.178,14.178,10.634,30.326,3.151,37.415c-3.151,3.151-7.483,3.151-11.422-0.788c-3.938-3.938-5.12-9.846-7.877-12.603c-2.363-2.363-5.908-1.969-8.271,0.394c-5.514,5.908,3.151,16.935,7.877,21.662c8.271,8.271,20.874,10.24,29.538,1.575C359.188,115.791,335.163,99.249,341.071,92.948z M385.969,113.428c-2.757-2.757-6.302-2.757-9.452,0l-34.658,34.658c-3.151,3.151-2.757,6.695,0,9.452c2.757,2.757,6.302,2.757,9.452,0l34.658-34.658C389.12,119.729,388.726,116.185,385.969,113.428zM439.926,167.385c-3.151-3.151-7.483-3.151-10.634-1.575l-31.114,16.935l16.935-31.508c1.575-2.757,1.182-7.483-1.575-10.634c-3.151-3.151-8.271-3.151-10.634-1.182l-39.385,28.357c-0.394,0.394-0.788,0.788-1.182,0.788c-2.757,2.757-2.363,6.302,0,8.665c3.151,3.151,5.514,2.757,9.058,0.394l28.357-21.268l-18.117,33.083c-1.182,1.969-1.969,5.12,1.182,8.271s6.302,2.363,8.271,1.182l32.689-18.117l-21.268,28.357c-2.363,3.151-2.757,5.908,0.394,9.058c2.363,2.363,5.908,2.757,8.665,0c0.394-0.394,0.788-0.788,0.788-1.182l28.357-39.778C443.077,175.655,443.077,170.535,439.926,167.385z"/>
    <path style={{fill: '#D7B354'}} d="M356.037,244.185l-21.268-21.662l-5.12-5.12l-52.775-53.563c-3.938-4.332-9.452-6.302-14.966-6.302s-10.634,1.969-14.966,6.302l-14.966,14.966l-10.634,10.634l-31.902,32.295l-1.969,1.969l-40.172,41.748l-10.634,10.634l-20.48,20.48c-8.271,8.271-8.271,21.662,0,29.932l20.874,21.268l5.514,5.514l26.782,27.175l10.634,10.634l15.36,15.754c3.938,4.332,9.452,6.302,14.966,6.302s10.634-1.969,14.966-6.302l20.086-20.48L256,375.729l100.037-101.612C364.308,265.846,364.308,252.455,356.037,244.185L356.037,244.185z M230.4,198.105l22.055,22.449l-31.902,32.689l-22.055-22.449C198.498,230.794,230.4,198.105,230.4,198.105z M187.471,241.428l22.055,22.449l-31.902,32.689l-22.055-22.449C155.569,274.117,187.471,241.428,187.471,241.428z M124.849,305.231l20.086-20.48L166.99,307.2l-26.782,27.175l-15.754-15.754C121.305,314.683,121.305,308.775,124.849,305.231z M151.237,345.403l122.486-124.849l-5.514-5.514l-27.175-27.963L256,171.717c1.575-1.575,3.938-2.757,6.302-2.757s4.726,0.788,6.302,2.757l52.775,53.957L177.625,372.185L151.237,345.403z M300.111,269.391l22.055,22.449l-31.902,32.295l-22.055-22.449L300.111,269.391z M216.615,398.966c-1.575,1.575-3.938,2.757-6.302,2.757c-2.363,0-4.726-0.788-6.302-2.757l-15.754-15.754l26.782-27.175l22.055,22.449C237.095,378.486,216.615,398.966,216.615,398.966zM225.674,345.009l31.902-32.689l22.055,22.449l-31.902,32.295C247.729,367.065,225.674,345.009,225.674,345.009z M347.766,265.846L332.8,280.812l-22.055-22.449l21.268-21.662l15.36,15.754C351.311,256,351.311,262.302,347.766,265.846z"/>
  </svg>
);

export default SimCard;
